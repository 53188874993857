import styled from '@emotion/styled';
import tw from 'twin.macro';

const Title = styled.h1`
  ${tw`text-3xl text-center text-primary-900 font-thin mt-1 mb-4`}
`;

const PStyled = styled.p`
  ${tw`text-center font-thin px-1 text-gray-600 leading-6 text-base`}

  b {
    ${tw`font-bold`}
  }
`;

export { Title, PStyled };
