import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import categories from '../../constants/categories.json';
import { returnWhatsapp } from '../../utils/browser';
import { Title, PStyled } from '../../styles/pages/search';
import Button from '../../components/button/button';
import Layout from '../../components/responsive/layout';
import Topbar from '../../components/responsive/topbar';
import { LangContext } from '../../context/lang.context';
import communicationService from '../../services/communication';

interface IServiceCompanyId {
  communicationToken: string;
  serviceCompanyId: string;
}
const ResumePage = ({ location }: PageProps) => {
  const windowGlobal: any = typeof window !== 'undefined' && window;
  const { state } = location;
  const { name, category, serviceCompanyId, communicationToken } = state ?? windowGlobal?.history?.state ?? {};
  const categoryName = category?.replace(/ /g, '_') || '';
  const categoryImage = categories.find(category => category.name === categoryName)?.image ?? 'water.png';
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allImages: allFile(filter: { extension: { regex: "/(png)/" }, relativeDirectory: { eq: "services" } }) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1000)
            }
          }
        }
      }
      allI18NJson {
        nodes {
          locale
          clientId
          SERVICES {
            ...categoriesText
            BUTTON {
              COMPANY
              ENTRY
            }
            RESUME {
              TITLE
              DESCRIPTION_1
              DESCRIPTION_2
              DESCRIPTION_DONATION
              BOTTOM_MESSAGE_1
              BOTTOM_MESSAGE_2
              THE_PAYMENT
              THE_DONATION
              BUTTON_TITLE
            }
          }
        }
      }
    }
  `);

  const imageData = data.allImages?.edges.find(({ node: { base } }: any) => base === categoryImage)?.node
    .childImageSharp;
  const isDonation = categoryName === 'Donaciones';

  const returnServiceCompanyId = ({ communicationToken, serviceCompanyId }: IServiceCompanyId) => {
    communicationService
      .postCommunicationToken({ token: communicationToken, text: `{${serviceCompanyId}}` })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => returnWhatsapp());
  };

  return (
    <Layout>
      <Topbar />
      <article className="content flex flex-col items-center">
        <Title>{t(data).SERVICES.RESUME.TITLE}</Title>
        <PStyled>
          {isDonation ? (
            <>
              {t(data).SERVICES.RESUME.DESCRIPTION_DONATION} <b>{name}</b>
            </>
          ) : (
            <>
              {t(data).SERVICES.RESUME.DESCRIPTION_1} <b>{name}</b> {t(data).SERVICES.RESUME.DESCRIPTION_2}{' '}
              <b>{t(data).SERVICES.CATEGORIES[categoryName]}</b>
            </>
          )}
        </PStyled>

        <GatsbyImage className="w-10/12 m-auto" loading="eager" image={imageData.gatsbyImageData} alt="Resume Search" />

        <PStyled>
          {t(data).SERVICES.RESUME.BOTTOM_MESSAGE_1}{' '}
          {isDonation ? t(data).SERVICES.RESUME.THE_DONATION : t(data).SERVICES.RESUME.THE_PAYMENT}
          <br></br>
          {t(data).SERVICES.RESUME.BOTTOM_MESSAGE_2}
        </PStyled>
        <Button color={'primary'} type="button" onClick={() => returnServiceCompanyId({ serviceCompanyId, communicationToken })} className='mt-8'>
          {t(data).SERVICES.RESUME.BUTTON_TITLE}
        </Button>
      </article>
    </Layout>
  );
};

export default ResumePage;
